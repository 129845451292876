<template>
  <div>
    {{ category }}
  </div>
</template>
<script>
  import {getTimeAttendance} from "@api/enzocard/services/time-attendance/time-attendance.api";

  export default {
    data: () => ({
      category: '',
    }),
    props: ['rulesTimeAttendanceId'],
    created () {
        if (this.rulesTimeAttendanceId) {
          getTimeAttendance(this.rulesTimeAttendanceId)
            .then((response) => {
              this.category = response.data.name;
            })
          ;
        }
    }
  }
</script>
