import {_delete, _get, _post, _put} from "@api/enzocard/services/httpService";

const URI = 'time-attendances/';

export const postTimeAttendance = (timeAttendance) => _post(URI, timeAttendance);

export const putTimeAttendance = (timeAttendance) => _put(URI + timeAttendance.id  + '/', timeAttendance);

export const deleteTimeAttendance = (id) => _delete(URI + id + '/');

export const getTimeAttendances = () => _get(URI);

export const getTimeAttendance = (id) => _get(URI + id);
